import {
  CreateSDModelDto,
  UpdateSDModelDto,
  FileAssociatedResource,
  RecipeItemTag,
  SDBaseModel,
  SDModelVariant,
  SDModelStatus,
  SDModelItem,
} from "@/api/sdk"
import AspectRatio from "@/components/AspectRatio"
import IconButton from "@/components/IconButton"
import ImageUpload from "@/components/ImageUpload"
import FileUpload from "@/components/FileUpload"
import { ImageViewWithDelete } from "@/components/ImageUpload/ImageView"
import Input from "@/components/Input"
import Modal from "@/components/Modal"
import { DeleteIcon, UploadIcon, XIcon } from "@/components/shared/icons"
import { useToast } from "@/hooks"
import classNames from "classnames"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form"
import { AnimatePresence } from "framer-motion"
import AddTagInput from "@/components/Explore/Gallery/GalleryFeatures/AddTagInput"
import Selector from "@/components/Selector"
import { uploadFiles, useCreateSdModelMutation, useUpdateSdModelMutation } from "@/queries"
import { resizeImage } from "@/utils/resize-image"
import { handleConvertHEICtoJPG } from "@/utils/convert"
import { uploadModelFiles } from "@/queries/workspace/useUploadModelMutation"
import NotEnoughModelStorageModal from "./NotEnoughModelStorageModal"
import { SettingProvider } from "@/components/Setting/SettingProvider"
import { WorkspaceProvider } from "@/providers/WorkspaceProvider"
import { useUploadProgressStore } from "@/stores/useUploadProgressStore"

type CreateModelModalProps = {
  model?: SDModelItem
  isOpen: boolean
  onClose: () => void
}

export type PublishRecipeFormData = {
  override: boolean
  name: string
  description: string
  thumbnailUrl: string
  thumbnailId: string
  tags: RecipeItemTag[]
}

export const modelVariants = [
  { name: "Checkpoint", id: SDModelVariant.Normal },
  { name: "LoRA", id: SDModelVariant.Lora },
  { name: "ControlNet", id: SDModelVariant.Controlnet },
  { name: "Upscale", id: SDModelVariant.Upscaler },
]

export const baseModels = [
  { name: "SD 1.5", id: SDBaseModel.Sd1X },
  { name: "SDXL", id: SDBaseModel.SdXl },
  { name: "FLUX1", id: SDBaseModel.Flux1 },
  { name: "Any", id: SDBaseModel.All },
]

const MODEL_SIZE_LIMIT = 10 * 1024 * 1024 * 1024 // 10GB
const MODEL_FILE_TYPES = ".sft,.safetensors,.pth,.pt"

export const uploadThumbnailFile = async (file: File) => {
  if (file.type === "image/heic") {
    const blob = await handleConvertHEICtoJPG(file)
    file = new File([blob], file.name, { type: file.type, lastModified: file.lastModified })
  }

  file = await resizeImage(file, 1024)

  return uploadFiles({ files: [file], resourceType: FileAssociatedResource.SD_MODEL }).then(([f]) => f)
}

const CreateModelModal = (props: CreateModelModalProps) => {
  const { model, isOpen, onClose } = props
  const [isOpenNotEnoughModelStorageModal, setIsOpenNotEnoughModelStorageModal] = useState(false)

  const form = useForm<CreateSDModelDto>({
    defaultValues: {
      variant: SDModelVariant.Normal,
      baseModel: SDBaseModel.Sd1X,
      name: "",
      description: "",
      modelFileId: undefined,
      thumbnailFileId: undefined,
    },
  })
  const { register, setValue } = form
  const toast = useToast()
  const [modelFile, setModelFile] = useState<File | null>(null)
  const [thumbnailFile, setThumbnailFile] = useState<File | null>(null)
  const [isUploadingThumb, setUploadingThumb] = useState(false)
  const [isUploadingModel, setUploadingModel] = useState(false)
  const [preview, setPreview] = useState<string | null>(null)
  const [tags, setTags] = useState<RecipeItemTag[]>([])
  const modelFilename = useMemo(
    () => (model && model.status !== SDModelStatus.DRAFT ? model.fileName : modelFile?.name ?? "").split("/").pop(),
    [model, modelFile],
  )

  const fileThumbnailRef = useRef<HTMLInputElement>(null)
  const fileModelRef = useRef<HTMLInputElement>(null)

  const [isSubmitting, setSubmitting] = useState(false)
  const uploadDisabled = model && model.status !== SDModelStatus.DRAFT

  const { progress, setUploadProgress } = useUploadProgressStore()

  const handleUploadThumbnail = async (file: File) => {
    if (file.size > 10 * 1000 * 1000) {
      toast({
        status: "error",
        title: "File size too large",
        message: ["Input image exceeds 10MB, please scale down the image and try again"],
      })
      return
    }

    setUploadingThumb(true)
    return uploadThumbnailFile(file).finally(() => {
      setUploadingThumb(false)
    })
  }

  const handleUploadModel = async (file: File) => {
    if (file.size > MODEL_SIZE_LIMIT) {
      toast({
        status: "error",
        title: "File size too large",
        message: ["Model file cannot exceed 10GB"],
      })
      return
    }

    setUploadingModel(true)
    return uploadModelFiles({
      files: [file],
      onProgress: progress => {
        setUploadProgress({
          ...progress,
          enable: true,
        })
      },
    })
      .catch(err => {
        if (err.message === "Not enough model storage") {
          setIsOpenNotEnoughModelStorageModal(true)
        } else {
          toast({
            status: "error",
            title: "Failed to upload model",
            message: [err.message],
          })
        }
      })
      .finally(() => {
        setUploadingModel(false)
        setUploadProgress({
          current: 0,
          currentFile: 0,
          total: 0,
          totalFile: 0,
          percent: 0,
          enable: false,
        })
      })
  }

  const { mutateAsync: createModel } = useCreateSdModelMutation()

  const { mutateAsync: updateModel } = useUpdateSdModelMutation()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const valid = await form.trigger()
    console.log(form.formState.errors)
    // form.clearErrors()
    if (!valid) return

    const values = form.getValues()
    const updateParams: Partial<UpdateSDModelDto> = {
      name: values.name,
      description: values.description,
    }

    let modelId = model?.id
    if (!modelId) {
      if (!thumbnailFile) {
        form.setError("thumbnailFileId", {
          message: "Thumbnail is required",
        })
        return
      }
      if (!modelFile) {
        form.setError("modelFileId", {
          message: "Model is required",
        })
        return
      }

      setSubmitting(true)
      const thumbnail = await handleUploadThumbnail(thumbnailFile)
      if (!thumbnail) return

      const model = await createModel(
        {
          ...values,
          thumbnailFileId: thumbnail.id,
          filename: modelFile.name,
          fileSize: modelFile.size,
        },
        {
          onError: err => {
            if (err.message === "Not enough model storage") {
              // show popup
              setIsOpenNotEnoughModelStorageModal(true)
              setSubmitting(false)
            }
          },
        },
      )
      modelId = model.id

      const file = await handleUploadModel(modelFile)
      if (!file || !file[0]) return

      updateParams.modelFileId = file[0].id
    } else {
      setSubmitting(true)
      if (thumbnailFile) {
        const thumbnail = await handleUploadThumbnail(thumbnailFile)
        if (!thumbnail) return

        updateParams.thumbnailFileId = thumbnail.id
      }
      if (modelFile) {
        const file = await handleUploadModel(modelFile)
        if (!file || !file[0]) return

        updateParams.modelFileId = file[0].id
      }
    }

    await updateModel({
      id: modelId,
      ...updateParams,
    })
      .then(() => {
        toast({
          status: "success",
          title: "Model uploaded",
        })
        onClose()
      })
      .catch(() => {
        toast({
          status: "error",
          title: "Failed to upload model",
        })
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const handleCancel = () => {
    form.reset({
      name: "",
      description: "",
      variant: SDModelVariant.Normal,
      baseModel: SDBaseModel.Sd1X,
    })
    if (fileModelRef.current) {
      fileModelRef.current.value = ""
    }
    setModelFile(null)
    if (fileThumbnailRef.current) {
      fileThumbnailRef.current.value = ""
    }
    setThumbnailFile(null)
    setPreview(null)
    setSubmitting(false)
    setIsOpenNotEnoughModelStorageModal(false)
    onClose()
  }

  useEffect(() => {
    if (model) {
      form.reset({
        name: model.name,
        description: model.description,
        variant: model.variant,
        baseModel: model.baseModel,
      })

      setPreview(model.thumbnailUrl)
    } else {
      form.reset({
        name: "",
        description: "",
        variant: SDModelVariant.Normal,
        baseModel: SDBaseModel.Sd1X,
      })
    }
  }, [model])

  useEffect(() => {
    if (!isOpen) {
      handleCancel()
    }
  }, [isOpen])

  return (
    <Modal
      title={model ? "Edit Uploaded Model" : "Upload your Model"}
      className="overflow-visible max-w-md"
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <FormProvider {...form}>
          <div className="flex flex-col gap-1">
            <label className="block font-semibold text-atherGray-300 text-sm">
              Model <span className="text-red-500">*</span>
            </label>
            <FileUpload
              isLoading={isUploadingModel}
              fileInputRef={fileModelRef}
              upload={async files => {
                setModelFile(files[0])
              }}
              progress={progress}
              className={classNames("w-full h-[8rem]", {
                "border-transparent": !!preview,
              })}
              accept={MODEL_FILE_TYPES}
              disabled={uploadDisabled}
            >
              {modelFilename ? (
                <div className="flex flex-col items-center justify-center p-4 h-full">
                  <div className="flex items-center justify-between w-full flex-1">
                    <p className="line-clamp-2">{modelFilename}</p>
                    <div className="">
                      <IconButton
                        onClick={e => {
                          e.stopPropagation()
                          setModelFile(null)
                          fileModelRef.current!.value = ""
                        }}
                        colorScheme="secondary"
                        className="flex items-center p-2"
                        disabled={uploadDisabled}
                      >
                        <DeleteIcon className="text-red-500" width={16} height={16} />
                      </IconButton>
                    </div>
                  </div>
                </div>
              ) : (
                <AspectRatio ratio={197 / 63}>
                  <div className="flex flex-col items-center justify-center h-full">
                    <div className="p-2 mb-2 rounded-full bg-atherPurple-500">
                      <UploadIcon />
                    </div>
                    <p className="text-atherGray-300 text-center">Max size: 10GB/model</p>
                    <p className="text-atherGray-300 text-center">Kind: .sft, .safetensors, .pth</p>
                  </div>
                </AspectRatio>
              )}
            </FileUpload>

            {form.formState.errors.modelFileId?.message && (
              <p className="text-red-500 text-xs mt-1">{form.formState.errors.modelFileId?.message}</p>
            )}
          </div>
          <div className="flex gap-2">
            <div className="flex flex-col gap-1 flex-1">
              <label className="block font-semibold text-atherGray-300 text-sm">
                Model Type <span className="text-red-500">*</span>
              </label>
              <Controller
                name="variant"
                rules={{ required: { value: true, message: "This field is required" } }}
                render={({ field }) => (
                  <>
                    <Selector
                      placement="bottom"
                      titleClassName="font-semibold"
                      className="bg-transparent border border-atherGray-800 text-atherGray-300"
                      value={modelVariants.find(v => v.id === field.value)?.name ?? "Checkpoint"}
                      options={modelVariants}
                      onSelect={v => {
                        form.setValue("variant", v.id)
                      }}
                    />
                  </>
                )}
              ></Controller>
            </div>
            <div className="flex flex-col gap-1 flex-1">
              <label className="block font-semibold text-atherGray-300 text-sm">
                Model Version <span className="text-red-500">*</span>
              </label>
              <Controller
                name="baseModel"
                rules={{ required: { value: true, message: "This field is required" } }}
                render={({ field }) => (
                  <Selector
                    placement="bottom"
                    titleClassName="font-semibold"
                    className="bg-transparent border border-atherGray-800 text-atherGray-300"
                    value={baseModels.find(v => v.id === field.value)?.name ?? "SD 1.5"}
                    options={baseModels}
                    onSelect={v => {
                      form.setValue("baseModel", v.id)
                    }}
                  />
                )}
              ></Controller>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <label className="block font-semibold text-atherGray-300 text-sm">
              Model name <span className="text-red-500">*</span>
            </label>
            <Controller
              name="name"
              rules={{ required: { value: true, message: "This field is required" } }}
              render={({ field, fieldState }) => (
                <>
                  <Input className="rounded-md px-2 text-atherGray-100" placeholder="Enter name" {...field} />
                  {fieldState.error && <p className="text-red-500 text-xs">{fieldState.error.message}</p>}
                </>
              )}
            ></Controller>
          </div>

          <div className="flex flex-col gap-1">
            <p className="font-semibold text-whiteAlpha-900 text-sm pb-1 mt-2">Tags</p>
            <div className={classNames("rounded-md flex bg-atherGray-800 flex-wrap w-full min-h-[2.25rem] p-2 gap-1")}>
              {tags?.map(tag => (
                <div key={tag.id} className="flex items-center px-2 py-0.5 border rounded-lg border-atherGray-700">
                  <p className="mr-1 text-atherGray-300 text-sm">{tag.name}</p>
                  <button
                    className={classNames("flex items-center cursor-pointer justify-center")}
                    type="button"
                    onClick={() => {
                      setTags(tags => tags.filter(t => t.id !== tag.id))
                    }}
                  >
                    <XIcon width={10} height={10} className="text-atherGray-300" />
                  </button>
                </div>
              ))}
              <AnimatePresence mode="wait">
                <AddTagInput
                  className="bg-atherGray-700"
                  tags={tags}
                  setTags={setTags as any}
                  // onTag={tag => {
                  //   onAddTagSuccess(tag as Tag)
                  // }}
                />
              </AnimatePresence>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <label className="block font-semibold text-atherGray-300 text-sm">Description</label>
            <div className="relative overflow-hidden rounded-md py-1 bg-atherGray-800">
              <textarea
                {...register("description")}
                className="bg-transparent w-full pr-8 h-[5rem] py-1 px-2 text-sm"
                placeholder={"Enter description"}
              />
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <label className="block font-semibold text-atherGray-300 text-sm">
              Thumbnail <span className="text-red-500">*</span>
            </label>
            <ImageUpload
              isLoading={isUploadingThumb}
              fileInputRef={fileThumbnailRef}
              upload={async files => {
                setThumbnailFile(files[0])
                setPreview(URL.createObjectURL(files[0]))
              }}
              className={classNames("w-full", {
                "border-transparent": !!preview,
              })}
            >
              {preview ? (
                <ImageViewWithDelete
                  className="w-full"
                  url={preview}
                  alt="Model Thumbnail"
                  onDelete={() => {
                    setThumbnailFile(null)
                    URL.revokeObjectURL(preview)

                    setPreview(null)
                  }}
                  preferThumbnail
                />
              ) : (
                <AspectRatio ratio={197 / 80}>
                  <div className="flex flex-col items-center justify-center h-full">
                    <div className="p-2 mb-2 rounded-full bg-atherPurple-500">
                      <UploadIcon />
                    </div>
                    <p className="text-atherGray-300 text-center">
                      Upload images or Drag & Drop <br />
                      (max 2048px and less than 10MB)
                    </p>
                  </div>
                </AspectRatio>
              )}
            </ImageUpload>
            {form.formState.errors.thumbnailFileId?.message && (
              <p className="text-red-500 text-xs mt-1">{form.formState.errors.thumbnailFileId?.message}</p>
            )}
          </div>

          <div className="flex justify-end space-x-2">
            <IconButton
              colorScheme="secondary"
              className="text-xs"
              onClick={handleCancel}
              disabled={isSubmitting}
              type="button"
            >
              Cancel
            </IconButton>
            <IconButton className="text-xs" type="submit" isLoading={isSubmitting}>
              {model ? "Save" : "Upload"}
            </IconButton>
          </div>
        </FormProvider>
      </form>

      <WorkspaceProvider>
        <SettingProvider>
          <NotEnoughModelStorageModal
            isOpen={isOpenNotEnoughModelStorageModal}
            onClose={() => setIsOpenNotEnoughModelStorageModal(false)}
          />
        </SettingProvider>
      </WorkspaceProvider>
    </Modal>
  )
}

export default CreateModelModal
