import React from "react"
import Modal from "@/components/Modal"
import { useAuth } from "@/providers/authContext"
import { useSetting } from "@/components/Setting/SettingProvider"
import { useGetListModelStorageSubscriptionQuery } from "@/queries"
import LoadingLogo from "@/components/LoadingLogo"
import classNames from "classnames"
import IconButton from "@/components/IconButton"
import { SubscriptionPackageItem } from "@/api/sdk"
import useCustomRouter from "@/hooks/useCustomRouter"

interface NotEnoughModelStorageModalProps {
  isOpen: boolean
  onClose: () => void
}

const NotEnoughModelStorageModal: React.FC<NotEnoughModelStorageModalProps> = ({ isOpen, onClose }) => {
  const router = useCustomRouter()
  const { user } = useAuth()
  const { currentWorkspaceDetail } = useSetting()
  const { data: listModelStorageSubscription = [], isLoading: isLoadingModelStorageSubscriptions } =
    useGetListModelStorageSubscriptionQuery()

  const isOwner = currentWorkspaceDetail?.owner?.uid === user?.uid

  const handleClick = (subscription: SubscriptionPackageItem) => {
    router.push(`/settings/upgrade-account?subscriptionId=${subscription.id}&tab=Monthly`, undefined, {
      shallow: true,
      scroll: true,
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Not Enough Model Storage">
      {isOwner ? (
        isLoadingModelStorageSubscriptions ? (
          <div className="flex items-center justify-center h-64">
            <LoadingLogo />
          </div>
        ) : (
          <div>
            <div>
              <p className="mb-2">You do not have enough storage to add the Model.</p>
              <p className="mb-2">Please purchase additional storage for your workspace.</p>
            </div>
            <div>
              {listModelStorageSubscription.map(subscription => (
                <div key={subscription.id} className={classNames("py-2 flex items-center")}>
                  <div className="flex-1">
                    <div className="flex items-center">
                      <h3 className="text-sm md:text-xl">{`${subscription.maxModelStorage / 1024 / 1024 / 1024}GB/workspace/month`}</h3>
                    </div>
                  </div>
                  <h3 className="mx-0 md:mx-6 text-xl font-semibold">${subscription.monthlyCost}</h3>
                  <IconButton onClick={() => handleClick(subscription)} className="text-xs md:text-sm hidden md:flex">
                    Buy Now
                  </IconButton>
                </div>
              ))}
            </div>
          </div>
        )
      ) : (
        <div>
          <p className="mb-2">You do not have enough model storage to add the Model.</p>
          <p>Please ask your workspace owner to purchase additional model storage.</p>
        </div>
      )}
    </Modal>
  )
}

export default NotEnoughModelStorageModal
