import client, { WEBSOCKET_URL } from "@/api/client"
import {
  CheckIfWhiteListedEmailsResult,
  NotificationWithStatus,
  PaymentMethod,
  ProductionType,
  UserDetail,
} from "@/api/sdk"
import useReferralUser from "@/components/ModalsPortal/SignInModalV2/useReferralUser"
import usePushNotificationToast from "@/components/NotificationDrawer/usePushNotificationToast"
import { useToast } from "@/hooks"
import useTelegramInitData from "@/hooks/telegram/useTelegramInitData"
import useCustomRouter from "@/hooks/useCustomRouter"
import {
  useGetPaymentInfoQuery,
  useGetUserPlanQuery,
  useUserWorkspaceDetailQuery,
  useWhitelistUserQuery,
} from "@/queries"
import { useMutateAddFcmToken } from "@/queries/useMutateAddFcmToken"
import { useEarlyAccessStore, usePaymentSuccessStore, useSignInStore, useUserChangedStore } from "@/stores"
import { isAcceptedEmail } from "@/utils"
import { UseQueryResult, useQuery, useQueryClient } from "@tanstack/react-query"
import { User, signInWithCustomToken } from "firebase/auth"
import { jwtDecode } from "jwt-decode"
import _ from "lodash"
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { Socket, io } from "socket.io-client"
import { auth } from "../lib/firebaseClient"

interface AuthContextType {
  bearerToken: string | null
  userInfoQuery: UseQueryResult<UserDetail, Error> | undefined
  user: User | null
  isInternalUser: boolean
  loading: boolean
  handleSignIn: (isAcceptedEmail?: boolean) => Promise<boolean>
  logout: () => Promise<void>
  tokenFormat: string
  socketRef: React.MutableRefObject<Socket | undefined>
  socketConnected: boolean
  whiteListUser: CheckIfWhiteListedEmailsResult | undefined
}

type SocketPaymentData = {
  userId: string
  paymentMethod: PaymentMethod
  productType: ProductionType
}

const AuthContext = createContext<AuthContextType>({
  bearerToken: null,
  userInfoQuery: undefined,
  user: null,
  handleSignIn: async () => false,
  loading: true,
  isInternalUser: false,
  logout: () => Promise.resolve(),
  tokenFormat: "",
  socketRef: { current: undefined },
  socketConnected: false,
  whiteListUser: undefined,
})

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const router = useCustomRouter()
  const socketRef = useRef<Socket>()

  const [userAuth, setUserAuth] = useState<User | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [bearerToken, setBearerToken] = useState<string | null>(null)
  const [socketConnected, setSocketConnected] = useState<boolean>(false)

  const isInternalUser = useMemo(() => {
    return isAcceptedEmail(userAuth?.email ?? "")
  }, [userAuth?.email])

  const setUserChanged = useUserChangedStore(state => state.setUserChanged)
  const setPaymentOpen = usePaymentSuccessStore(state => state.setIsOpen)
  const setSignInModal = useSignInStore(state => state.setSignInModal)
  const { mutate: mutateAddFcmToken } = useMutateAddFcmToken()

  const telegramInitData = useTelegramInitData()
  const { handleSignInSuccess } = useReferralUser(() => {})

  const toast = useToast({
    position: "top-right",
  })

  const tokenFormat = useMemo(
    () => bearerToken?.slice(bearerToken.length - 20, bearerToken.length) ?? "",
    [bearerToken],
  )

  const res = useQuery({
    queryKey: ["user-info", userAuth?.uid],
    queryFn: () => client.api.userControllerGetUserSelfInfo().then(res => res.data),
    enabled: !!tokenFormat && !!userAuth,
  })

  const {
    data: whiteListUser,
    isLoading: isLoadingWhiteListUser,
    isSuccess: isSuccessWhiteListUser,
  } = useWhitelistUserQuery({
    variables: {
      emails: [userAuth?.email ?? ""],
    },
    enabled: !!userAuth,
  })

  const apiInterceptorRef = useRef<number>()

  const redirectFlag = useRef(true)
  const logout = async () => {
    redirectFlag.current = false
    await auth.signOut()
    if (apiInterceptorRef.current !== undefined) client.instance.interceptors.request.eject(apiInterceptorRef.current)
    setUserAuth(null)
  }

  useEffect(() => {
    const tokenRefreshUnsubscribe = auth.onIdTokenChanged(async userAuth => {
      if (userAuth) {
        const token = await userAuth.getIdToken()
        setBearerToken(token)
        return
      }

      setBearerToken(null)
    })

    return () => {
      tokenRefreshUnsubscribe()
    }
  }, [userAuth])

  const refreshTokenTimer = useRef<NodeJS.Timer>()
  useEffect(() => {
    if (!bearerToken) return

    const cleanUp = () => {
      if (refreshTokenTimer.current) {
        clearTimeout(refreshTokenTimer.current)
      }
    }

    const decoded = jwtDecode(bearerToken)
    if (decoded.exp) {
      cleanUp()

      const time = Math.max(0, decoded.exp * 1000 - Date.now() - 300000)
      refreshTokenTimer.current = setTimeout(async () => {
        const user = auth.currentUser
        if (user) {
          const token = await user.getIdToken(true)
          setBearerToken(token)
        }
      }, time)
    }

    return cleanUp
  }, [bearerToken])

  const isNotAuth = useMemo(
    () =>
      router.pathname.includes("/terms-of-service") ||
      router.pathname === "/" ||
      router.pathname === "/landing" ||
      router.pathname.includes("/privacy-policy") ||
      router.pathname.includes("/r/[redirectId]") ||
      router.pathname.includes("/w/[username]/[redirectId]") ||
      router.pathname.includes("/workspace/images/") ||
      // router.pathname.includes("/workspace/tools/recipes/") ||
      // router.pathname.includes("/workspace/macros/") ||
      router.asPath.includes("/alpha") ||
      router.asPath.includes("/ai-recipes") ||
      router.asPath.includes("/prompt-book") ||
      router.asPath.includes("/explore") ||
      router.asPath.includes("/landing-agent-scheduler") ||
      router.asPath.includes("/tips-and-tricks") ||
      router.asPath.includes("/profile/") ||
      router.asPath.includes("/accept-invite") ||
      router.asPath.includes("/auth/verify-new-email") ||
      router.asPath.includes("/secure-account") ||
      router.pathname.includes("/leaderboard") ||
      router.pathname.includes("/events") ||
      // router.pathname.includes("/my-creations") ||
      router.pathname.includes("/quests") ||
      router.pathname.includes("/updates"),
    [router.asPath, router.pathname],
  )

  const handleSignIn = useCallback(
    async (isAccepted?: boolean) => {
      if (!userAuth) {
        setSignInModal({
          signIn: true,
        })
        return false
      }

      // if (!isAcceptedEmail(userAuth.email ?? "") && !whiteListUser?.isWhitelisted && isAccepted) {
      //   toast({
      //     status: "error",
      //     title: "Your account is not in the whitelist",
      //     message: ["Please sign up for our free Early Access program and we'll add you to the next trial."],
      //   })

      //   return false
      // }

      return true
    },
    [userAuth],
  )

  const checkRedirect = useCallback(
    (_userAuth: User | null) => {
      const isAuthRoute = router.pathname.startsWith("/auth")

      if (isNotAuth) return

      if (loading || isLoadingWhiteListUser) return

      if (!_userAuth) {
        if (!isAuthRoute) {
          if (redirectFlag.current) {
            const next = router.asPath

            router.replace(`/auth?next=${encodeURIComponent(next)}`, undefined, { shallow: true })
          } else {
            router.replace(
              "/auth",
              {
                query: {
                  signIn: true,
                },
              },
              { shallow: true },
            )
            redirectFlag.current = true
          }
        }
      } else {
        // if (!whiteListUser) return

        // if (!isAcceptedEmail(_userAuth.email!) && !whiteListUser.isWhitelisted) {
        //   router.replace("https://protogaia.com/explore")

        //   return
        // }

        if (isAuthRoute) {
          const next = router.query.next as string

          let asPath = next ? decodeURIComponent(next) ?? "/workspace" : "/workspace"

          try {
            const isNextLocal = new URL(document.baseURI).origin === new URL(asPath, document.baseURI).origin
            asPath = isNextLocal ? (asPath ? asPath : new URL(asPath, document.baseURI).pathname) : "/"
          } catch {
            asPath = "/"
          }

          router.replace(asPath as string, undefined, { shallow: true })
        }
      }
    },
    [router, loading, isNotAuth, isLoadingWhiteListUser],
  )

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      if (user) {
        if (isLoadingWhiteListUser) return

        // check if email is not verified then consider it as not logged in
        if (!user.emailVerified) {
          await logout()
          setLoading(false)

          toast({
            status: "error",
            title: "Email not verified",
            message: ["Please verify your email to continue."],
          })

          return
        }

        // if (!isAcceptedEmail(user.email!) && whiteListUser && !whiteListUser.isWhitelisted) {
        //   await logout()
        //   setLoading(false)

        //   toast({
        //     status: "error",
        //     title: "Oops! Can't access GAIA yet!",
        //     message: [
        //       "Sorry friend, you are not whitelisted to access the GAIA platform at the moment. Please enjoy the Guest mode with  5 free Generate times. Stay tuned ! We will open publicly soon",
        //     ],
        //     duration: 10000,
        //   })

        //   return
        // }

        setUserAuth(user)
      }

      setLoading(false)
    })

    return () => {
      unsubscribe()
    }
  }, [isLoadingWhiteListUser, toast])

  useEffect(() => {
    if (loading) return

    if (!userAuth) return

    const fcmToken = localStorage.getItem("fcm-token")

    if (fcmToken) {
      mutateAddFcmToken({ token: fcmToken })
    }
  }, [userAuth, loading])

  useEffect(() => {
    const timer = setTimeout(() => {
      checkRedirect(userAuth)
    }, 1)

    return () => clearTimeout(timer)
  }, [checkRedirect, userAuth])

  const pushNotificationToast = usePushNotificationToast()
  const qc = useQueryClient()

  useEffect(() => {
    const isInIframe = window.self !== window.top
    if (isInIframe) return console.log("skip socket noti on iframe")

    if (loading) return

    if (bearerToken) {
      // Disconnect from the anonymous room first
      if (socketRef.current?.connected) {
        socketRef.current.disconnect()
      }
      socketRef.current = io(`${WEBSOCKET_URL}/api/notification`, {
        reconnectionDelayMax: 10000,
        reconnectionAttempts: 5,
        extraHeaders: {
          Authorization: `Bearer ${bearerToken}`,
        },
        withCredentials: true,
      })

      socketRef.current.on("update-credit", () => {
        qc.invalidateQueries({ queryKey: ["user-info", userAuth?.uid] })
      })

      socketRef.current.on("payment-card-updated", (data: SocketPaymentData) => {
        if (data?.userId === userAuth?.uid) {
          if (data.productType === ProductionType.ChangePaymentMethod) {
            toast({
              status: "success",
              title: "Change completed",
              message: ["Your payment method has been changed successfully."],
            })
            qc.invalidateQueries({ queryKey: useGetPaymentInfoQuery.getKey() })
            qc.invalidateQueries({ queryKey: useGetUserPlanQuery.getKey() })
          }
        }
      })

      socketRef.current.on("payment-completed", (data: SocketPaymentData) => {
        if (data?.userId === userAuth?.uid) {
          qc.invalidateQueries({ queryKey: ["user-info", userAuth?.uid] })

          if (
            data?.productType === ProductionType.Subscription ||
            data?.productType === ProductionType.SubscriptionSeat
          ) {
            qc.invalidateQueries({ queryKey: useUserWorkspaceDetailQuery.getKey() })
            qc.invalidateQueries({ queryKey: useGetUserPlanQuery.getKey() })

            if (data?.productType === ProductionType.Subscription) {
              router.replace("/settings/account?tab=Billing")
              setPaymentOpen(false)
            }
          }
          toast({
            status: "success",
            title: "Payment completed",
            message: ["Your payment has been completed successfully."],
          })
        }
      })

      socketRef.current.on("email-changed", data => {
        setUserChanged(data)
      })

      socketRef.current.on("notification", async (notification: NotificationWithStatus) => {
        if (
          router.asPath.includes("/alpha") ||
          router.asPath.includes("/ai-recipes") ||
          router.asPath.includes("/landing-agent-scheduler")
        )
          return

        pushNotificationToast({ notification, duration: 10000 })

        qc.invalidateQueries({ queryKey: ["notifications"] })

        qc.invalidateQueries({ queryKey: ["infinite-comment-workflows"] })
        qc.invalidateQueries({ queryKey: ["infinite-comment-styles"] })
        qc.invalidateQueries({ queryKey: ["infinite-comment-sd-workflows"] })

        qc.setQueryData(["new-notifications-count"], (prev: number | undefined) => (prev === undefined ? 1 : prev + 1))
      })
    } else {
      socketRef.current = io(`${WEBSOCKET_URL}/api/notification`, {
        reconnectionDelayMax: 10000,
        reconnectionAttempts: 5,
        withCredentials: true,
      })
    }

    if (socketRef.current && !socketRef.current.connected) {
      socketRef.current.connect()

      socketRef.current.on("connect", () => {
        console.log("socket connected")
        setSocketConnected(true)
      })
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.off("notification")
        socketRef.current.off("update-credit")
        socketRef.current.off("task-progress")
        socketRef.current.off("payment-completed")
        socketRef.current.disconnect()
        setSocketConnected(false)
      }
    }
  }, [bearerToken, loading, userAuth?.uid]) // eslint-disable-line

  // check user is logged in from telegram
  useEffect(() => {
    if (!telegramInitData || _.isEmpty(telegramInitData)) return

    client.api.telegramAuthControllerAuthorize(telegramInitData).then(res => {
      if (res.data.customToken) {
        signInWithCustomToken(auth, res.data.customToken).then(credentials => {
          handleSignInSuccess({ credentials, provider: "telegram" })
        })
      }
    })
  }, [telegramInitData, handleSignInSuccess])

  return (
    <AuthContext.Provider
      value={{
        socketRef,
        tokenFormat,
        handleSignIn,
        bearerToken,
        whiteListUser,
        isInternalUser,
        user: userAuth,
        userInfoQuery: res,
        loading: isLoadingWhiteListUser || loading,
        logout,
        socketConnected,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
